<template>
  <div class="flight_box_three_body_two">
    <div class="d-flex justify-content-between">
      <div class="right_box d-flex">
        <div class="col-7 p-1 d-flex">
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countAdult" @change="changePaxCount">
              <template #first>
                <b-form-select-option :value="0" disabled>{{ $t("search-tab.adult") }}</b-form-select-option>
              </template>
              <option v-for="inx in 21" :key="inx" :value="inx - 1">{{ `${inx - 1} ` }}{{ $t("search-tab.adult") }}</option>
            </b-form-select>
          </div>
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countChild" @change="changePaxCount">
              <template #first>
                <b-form-select-option :value="0" disabled>{{ $t("search-tab.child") }}</b-form-select-option>
              </template>
              <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ `${inx - 1} ` }}{{ $t("search-tab.child") }}</option>
            </b-form-select>
          </div>
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countInfant" @change="changePaxCount">
              <template #first>
                <b-form-select-option :value="0" disabled>{{ $t("product-page.infant") }}</b-form-select-option>
              </template>
              <option v-for="inx in 2" :key="inx" :value="inx - 1">{{ `${inx - 1} ` }}{{ $t("product-page.infant") }}</option>
            </b-form-select>
          </div>
        </div>
        <div class="col-5 p-2 pr-3 align-self-center" :class="{'text-center': device !== 'desktop'}">
          <h5>{{ $t("flight-only.total-to-pay") }}</h5>
          <h3>{{getPriceWithSymbol(currencySymbol, total)}}</h3>
          <!--<h4>
            Total to pay <strong>$ {{ total }}</strong>
          </h4>-->
          <button class="btn-book-now" :class="{'ml-4': device=== 'desktop'}" @click="gotoBook">
            {{ $t("home.book-now.book-now") }}
          </button>
        </div>
      </div>
      <special-price v-if="device === 'desktop'" :occupancy="occupancy"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
    SpecialPrice: () => import('@/components/bookingFlightOnly/SpecialPrice'),
  },
  props: {
    occupancy: {
      type: Object,
      default: null,
    },
    airlineNotConfirmed: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    average: {
      type: Number,
      default: 0,
    },
    infoFlight: {
      type: Object,
      default: () => ({
        name: 'Blue Bird Air...',
        description: 'Lorem ipsum dolor sit amet,',
      }),
    },
    currencySymbol: {
      type: String,
      default: 'US',
    },
  },
  mixins: [gMixin],
  data() {
    return {
      query: '',
      totalPrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult
          ? this.$store.getters.GET_SEARCH_CONTENT.adult
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child
          ? this.$store.getters.GET_SEARCH_CONTENT.child
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant
          ? this.$store.getters.GET_SEARCH_CONTENT.infant
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
  },
  created() {
    this.query = this.$route.query;
    this.countAdult = this.query.adult;
    this.countChild = this.query.child;
    this.countInfant = this.query.infant;
  },
  methods: {
    changePaxCount() {
      // console.log(this.countAdult, this.countChild, this.countInfant)
      this.$emit('emitUpdatePax');
    },
    gotoBook() {
      this.$emit('emitBook');
    },
  },
};
</script>

<style scoped>
.right_box {
  margin: auto;
  width: 50%;
}
.right_box div.form-group {
  padding: 2px;
  margin: auto;
}

@media (max-width: 476px) {
  .flight_box_three_body_two .right_box .d-flex {
    display: inline-block !important;
    padding: 0px;
  }
  .right_box > div.col-6 {
    padding: 2px;
  }
  .right_box div.form-group {
    padding: 0px;
    max-width: 100%;
  }
  .flight_only_body .flight_box_three .flight_box_three_body_two .right_box {
    width: 100%;
  }

  .flight_box_three_body_two .right_box h5 {
    font-size: 12px;
  }
  .flight_box_three_body_two .right_box h3 {
    font-size: 21px;
  }
}
</style>
